import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  createTheme,
  styled,
  Typography,
} from "@material-ui/core";
import { defaultAvatar } from "../../blocks/user-profile-basic/src/assets";
import { arrowIcon } from "../../blocks/apiintegration/src/assets";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  shareIcon,
  commentIcon
} from "../../blocks/landingpage/src/assets";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2.web";
import { IPostData } from "../../blocks/landingpage/src/FeedController";
import ContentModeration from "../../blocks/contentmoderation/src/ContentModeration.web";
import Comments from "../../blocks/comments/src/Comments";
import { navLogo } from "../../blocks/navigationmenu/src/assets"

interface Props {
  description: string;
  liked: boolean;
  likeCount: number;
  index?: number;
  likeBy: string;
  likeByUser: {
    id: number;
    email: string;
  };
  postByUser: {
    id: number;
    email: string;
  };
  userProfile: string;
  postMedia: IMedia[];
  commentCount: number;
  postId: number;
  navigationFn: (path: string, param?: string | number) => void;
  isRepost: boolean;
  repostData: {
    data: IPostData;
  };
  postData: IPostData;
  navigation: any;
  title?: string;
  getData?: () => void;
}

interface IMedia {
  id: number;
  url: string;
}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
});

const StyledCarousel = styled(Carousel)({
  "& .control-arrow": {
    background: "none !important",
    opacity: "1 !important",
  },
  "& .dot": {
    margin: "0px 4px !important",
    height: "10px !important",
    width: "10px !important",
  },

  "&.reposted": {
    "& .carousel-slider": {
      width: "100% !important",
    },
  }
});

const ShopNowBtn = styled(Typography)({
  backgroundColor: "#925B5B",
    color: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "65px",
    paddingInline: "25px",
    borderRadius: "0 0 8px 8px",
    marginTop: "-5px",
    cursor: "pointer"
});

const PostCard = (props: Props) => {
  const {
    title = "",
    postByUser,
    commentCount,
    description,
    liked,
    postId,
    isRepost,
    postMedia,
    navigationFn,
    repostData,
    postData,
    likeCount,
    userProfile,
    likeByUser,
    getData
  } = props;

  const [formatDescription, setformatDescription] = useState({
    description: "",
    showLess: false
  });
  const [repostFormatDescription, setRepostFormatDescription] = useState({
    description: repostData?.data?.attributes?.description,
    showLess: false
  });
  const [likeNum, setLikeNum] = useState(likeCount);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const [mutedVideos, setMutedVideos] = useState<boolean[]>([true]);
  

  const formatDescriptionFn = () => {  
    if (description?.length > 100) {
      let desc = description?.slice(0, 100);
      setRepostFormatDescription({
        description: repostData?.data?.attributes?.description?.slice(0, 100),
        showLess: false
      })
      setformatDescription({
        description: desc,
        showLess: false
      });
    }
    else {
      setRepostFormatDescription({
        description: repostData?.data?.attributes?.description?.slice(0, 100),
        showLess: false
      })
      setformatDescription({
        description,
        showLess: false
      });
    }
  };

  const handleExpand = (toggle:boolean = false, repost:any = false) => {
    let desc = description?.slice(0, 100);
    repost ? setRepostFormatDescription({
      description: toggle ? repostData?.data?.attributes?.description : repostData?.data?.attributes?.description?.slice(0, 100),
      showLess: toggle
    }) : setformatDescription({
      description: toggle ? description : desc,
      showLess: toggle
    });
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const video = entry.target as HTMLVideoElement;
          if (entry.isIntersecting) {
            video.play();
          } else {
            video.pause();
          }
        });
      },
      { threshold: 0.75 }
    );

    videoRefs.current.forEach((video) => {
      if (video) observer.observe(video);
    });

    formatDescriptionFn()

    return () => {
      observer.disconnect();
    };
  }, []);

  
  const handleMuteToggle = (index: number) => {
    setMutedVideos((prevMutedVideos) => {
      const newMutedVideos = [...prevMutedVideos];
      newMutedVideos[index] = !newMutedVideos[index];
      return newMutedVideos;
    });
  };

  const handleLikeCount = (count: number) => {
    setLikeNum(count)
  }

  return isRepost ? (
    <Box width={"880px"} height={"463px"} marginBottom={"35px"} display={"flex"} style={{
      padding: "16px",
      justifyContent: "center",
      boxSizing: "border-box",
      gap: "12px"
    }}>
      <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"542px"} style={{gap: "12px"}}>
        <Box>
          <Box display={"flex"} alignItems={"center"}>
            <Avatar
              alt="profile"
              src={userProfile ?? defaultAvatar}
              style={{
                height: "44px",
                width: "44px",
                border: "2px solid #F3E1DC",
              }}
            />
            <Box>
              <Typography
                style={{
                  marginLeft: "16px",
                  color: "#0F172A",
                  fontSize: "16px",
                  fontWeight: 600,
                  cursor: "pointer"
                }}
                onClick={() => navigationFn("UserProfileBasicBlock", postByUser?.id)}
              >
                {postByUser?.email?.split("@")[0]} <span style={{ marginLeft: "5px" }}>•</span>
                <span
                  style={{
                    color: "#64748B",
                    fontSize: "16px",
                    marginLeft: "5px",
                  }}
                >
                  {postData?.attributes?.created_at}
                </span>{" "}
              </Typography>
            </Box>
          </Box>

          <Box maxHeight={"72px"} overflow={"auto"}>
          <Typography style={{fontFamily: "Montserrat", fontWeight: 600, fontSize: "16px", lineHeight: "24px"}}>{repostData?.data?.attributes?.body}</Typography>
          <Typography style={{ color: "#334155", fontSize: "16px" }}>
                {repostFormatDescription.description}
                {description.length > 100 && <h5 style={{cursor: "pointer", margin: 0, fontSize: "16px", color: "#64748B", lineHeight: "24px"}}>
                    {repostFormatDescription.showLess ? <span onClick={() => handleExpand(false,true)}>Show less</span> : <span onClick={() => handleExpand(true, true)}>Show more</span>}
                  </h5>}
              </Typography>
          </Box>
        </Box>

        <StyledCarousel width={"100%"} className="reposted" showThumbs={false} showArrows={repostData?.data?.attributes?.images_and_videos?.length > 1} showStatus={false} showIndicators={repostData?.data?.attributes?.images_and_videos?.length > 1}>
          {repostData?.data?.attributes?.images_and_videos?.map((media, index) => {
            return new URL(media.url).searchParams.get("type") === "image" ? (
              <img
                src={media.url}
                style={{
                  height: "305px",
                  width: "100%",
                  borderRadius: "32px"
                }}
                alt=""
              />
            ) : (
              <video
                loop
                autoPlay
                ref={(el) => (videoRefs.current[index] = el)}
                muted={mutedVideos[index]}
                onClick={() => handleMuteToggle(index)}
                style={{
                  height: "305px",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "32px"
                }}
              >
                <source src={media?.url} />
              </video>
            );
          })}
        </StyledCarousel>
      </Box>
      <Box style={{ display: "flex", flexDirection: "column", gap: "12px", width: "294px" }}>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"294px"} minHeight={"44px"}>
          <Box display={"flex"}>
            <div style={{display: "flex"}}>
            <Avatar
              alt="profile"
              src={repostData?.data?.attributes?.post_by_user?.profile_image ?? defaultAvatar}
              style={{
                height: "44px",
                width: "44px",
                border: "2px solid #F3E1DC",
              }}
            />
            <Typography
                style={{
                  marginLeft: "16px",
                  color: "#0F172A",
                  fontSize: "16px",
                  fontWeight: 600,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center"
                }}
                onClick={() => navigationFn("UserProfileBasicBlock", repostData?.data?.attributes?.post_by_user?.id)}
              >
                {repostData?.data?.attributes?.post_by_user?.email?.split("@")[0]}
              </Typography>
            </div>
          </Box>
            <ContentModeration isFollowing={postData?.attributes?.is_follwed} orignalPostId={repostData?.data?.attributes?.id} getData={getData} title={title} navigation={props.navigation} id={""} userId={repostData?.data?.attributes?.post_by_user?.id} userName={repostData?.data?.attributes?.post_by_user?.email?.split("@")[0]} postId={postId} postByUser={postByUser} />
        </Box>
        <Box style={{ position: "relative", display: "flex", flexDirection: "column", gap: "12px" }} height={"100%"}>
            {likeNum > 0 && <Typography
              style={{ color: "#0F172A", fontSize: "16px" }}
            >
              Loved by
              <span style={{ fontWeight: 600, cursor: "pointer" }} onClick={() => navigationFn("UserProfileBasicBlock", repostData?.data?.attributes?.post_by_user?.id)}> {repostData?.data?.attributes?.like_by_user?.email?.split("@")[0]}</span>
              {likeNum > 1 && <span onClick={() => navigationFn("Loves", postId)} style={{ fontWeight: 600, cursor: "pointer" }}> and {likeNum - 1} others</span>}
            </Typography>}
          <Box height={"100%"} overflow={"auto"}>
          <Typography style={{fontFamily: "Montserrat", fontWeight: 600, fontSize: "16px", lineHeight: "24px"}}>{postData?.attributes?.title || postData?.attributes?.body}</Typography>
            <Typography
            style={{ color: "#334155", fontSize: "16px" }}
          >
            {formatDescription.description}
            {description.length > 100 && <h5 style={{cursor: "pointer", margin: 0, fontSize: "16px", color: "#64748B", lineHeight: "24px"}}>
                {formatDescription.showLess ? <span onClick={() => handleExpand(false)}>Show less</span> : <span onClick={() => handleExpand(true)}>Show more</span>}
              </h5>}
          </Typography>
          </Box>
          <Box display={"flex"} style={{ gap: "12px" }}>
            <div style={{display: 'flex'}}>
            <Likeapost2
              navigation={undefined}
              id={""}
              postId={postId}
              isLiked={repostData?.data?.attributes.liked}
              updateCount={handleLikeCount}
            />
            <Typography data-test-id="like-text" style={{fontFamily: "Montserrat, sans-serif",marginLeft:"5px"}}>{likeNum || ""}</Typography>
            </div>
            <img
              src={commentIcon}
              alt=""
              onClick={() => navigationFn("ViewPost", postId)}
              style={{ cursor: "pointer" }}
            />
            <img
              src={shareIcon}
              alt=""
              onClick={() => navigationFn("Repost", postId)}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Comments title={title} navigation={undefined} id={""} postId={postId} commentCount={commentCount} />
        </Box>
      </Box>
    </Box>
  ): (
    <Box width={"880px"} height={"463px"} marginBottom={"35px"} display={"flex"} style={{
      padding: "16px",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
      gap: "12px"
    }}>
      {postData.type === "post" ? <StyledCarousel width={"542px"} showThumbs={false} showArrows={postMedia?.length > 1} showStatus={false} showIndicators={postMedia?.length > 1}>
         {postMedia?.map((media, index) => {
          return new URL(media.url).searchParams.get("type") === "image" ? (
            <img
              src={media.url}
              style={{
                height: "431px",
                width: "542px",
                borderRadius: "32px"
              }}
              alt=""
            />
          ) : (
            <video
              loop
              autoPlay
              ref={(el) => (videoRefs.current[index] = el)}
              onClick={() => handleMuteToggle(index)}
              muted={mutedVideos[index]}
              style={{
                height: "431px",
                width: "542px",
                objectFit: "cover",
                borderRadius: "32px"
              }}
            >
              <source src={media?.url} />
            </video>
          );
        })}
      </StyledCarousel> : <Box>
        <img src={postData.attributes.image}
              style={{
                height: "366px",
                width: "542px",
                borderTopLeftRadius: "32px",
                borderTopRightRadius: "32px"
              }}
              alt="" />
        <ShopNowBtn data-test-id="shopNow" onClick={() => window.open(postData.attributes.shopify_product_url, '_blank')} >
          <span style={{fontSize: "20px", fontWeight: 600}}>Shop now</span>
          <img src={arrowIcon} alt="" />
        </ShopNowBtn>
        </Box>}
      <Box display={"flex"} flexDirection={"column"} style={{ gap: "12px", width: "294px" }} height={"100%"}>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"294px"} maxHeight={"44px"} height={"100%"}>
          {
            postData.type === 'post' ? <Box display={"flex"} alignItems={"center"}>
            <Avatar
              alt="profile"
              src={userProfile ?? defaultAvatar}
              style={{
                height: "44px",
                width: "44px",
                border: "2px solid #F3E1DC",
              }}
            />
            <Typography
              style={{
                marginLeft: "16px",
                color: "#0F172A",
                fontSize: "16px",
                fontWeight: 600,
                cursor: "pointer"
              }}
              onClick={() => navigationFn("UserProfileBasicBlock", postByUser?.id)}
            >
              {postByUser?.email?.split("@")[0]}
            </Typography>
          </Box> : <img src={navLogo} alt="home-logo" />}
          <ContentModeration isFollowing={postData?.attributes?.is_follwed} getData={getData} title={title} navigation={props.navigation} postId={postId} id={""} userId={postByUser?.id} userName={postByUser?.email?.split("@")[0]} postByUser={postByUser} />
           
        </Box>
          {likeNum > 0 && <Typography
            style={{ color: "#0F172A", fontSize: "16px" }}
          >
            Loved by
            <span style={{ fontWeight: 600, cursor: "pointer" }} onClick={() => navigationFn("UserProfileBasicBlock", likeByUser?.id)}>&nbsp;{likeByUser?.email?.split("@")[0]} </span>

            {likeNum > 1 && <span style={{ fontWeight: 600, cursor: "pointer" }} onClick={() => navigationFn("Loves", postId)} >and {likeNum - 1} others</span>}
          </Typography>}
        <Box position={"relative"} height={"100%"} overflow={"auto"}>
          <Typography style={{fontFamily: "Montserrat", fontWeight: 600, fontSize: "16px", lineHeight: "24px"}}>{postData?.attributes?.title || postData?.attributes?.body}</Typography>
          <Typography
            style={{ color: "#334155", fontSize: "16px" }}
          >
            {formatDescription.description}
            {description?.length > 100 && <h5 style={{cursor: "pointer", margin: 0, fontSize: "16px", color: "#64748B", lineHeight: "24px"}}>
                {formatDescription.showLess ? <span onClick={() => handleExpand(false)}>Show less</span> : <span onClick={() => handleExpand(true)}>Show more</span>}
              </h5>}
          </Typography>
        </Box>
          <Box display={"flex"} style={{ gap: "12px" }}>
          <div style={{display: 'flex'}}>
          <Likeapost2
            navigation={undefined}
            id={""}
            postId={postId}
            isLiked={liked}
            updateCount={handleLikeCount}
            title={title}
          />
          <Typography data-test-id="like-text" style={{fontFamily: "Montserrat, sans-serif",marginLeft:"5px"}}>{likeNum || ""}</Typography>
          </div>
          <img
            src={commentIcon}
            alt=""
            onClick={() => title === 'ads' ? {} : navigationFn("ViewPost", postId)}
            style={{ cursor: "pointer" }}
          />
          <img
            src={shareIcon}
            alt=""
            onClick={() => title === 'ads' ? {} : navigationFn("Repost", postId)}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <Comments title={title} navigation={undefined} id={""} postId={postId} commentCount={commentCount} />
      </Box>
    </Box>
  )
};

export default PostCard;
