// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.validationApiContentType = "application/json";
exports.formDataApiContentType = "multipart/form-data";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "bx_block_content_moderation/moderation";
exports.exampleWebAPiEndPoint = "bx_block_content_moderation/moderation";
exports.codeLinkEndPoint = "bx_block_apiintegration52/shopify/purchase_archive_slot/";
exports.post_archiveEndPoint = "/bx_block_archive/archived_items/post_archive?code=";

exports.exampleAPiMethod = "POST";
exports.postMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Content Moderation";
exports.labelBodyText = "Content Moderation Body";
exports.validationApiMethodTypeUpdate = "PUT";
exports.btnExampleTitle = "CLICK ME";
exports.DeleteApiMethodType = "DELETE";
exports.InsertApiMethodType = "POST";
exports.loginEmail = "test11@gmail.com";
exports.loginPassword = "Password@123";
exports.loginEndpoint = "bx_block_login/logins";

exports.endpoints = {
  UNFOLLOW_USER: "bx_block_profile/follow_relationships",
  REPORT_POST: "bx_block_content_moderation/reports",
  SEND_REQUEST: "bx_block_profile/follow_relationships",
  GET_REPORT_REASONS: "bx_block_profile/follow_relationships"
}

exports.methodTypes = {
  HTTP_GET: "GET",
  HTTP_POST: "POST",
  HTTP_PUT: "PUT",
  HTTP_DELETE: "DELETE"
}

exports.apiContentType = "application/json";

exports.moderationList = {
  VIEW_PROFILE: "View Profile",
  UNFOLLOW_USER: "Unfollow",
  REPORT_POST: "Report",
  FOLLOW_USER: "Follow",
  REQUESTED: "Requested",
  ARCHIEVE: "Archive",
  EDIT: "Edit",
  DELETE: "Delete",
  ORIGINAL: "View original",
}

exports.feedbackMsg = {
  FETCH_ERR: "Something went wrong!",
  POST_REPORT: "Thank you for your report! Your report has been successfully submitted. We take all reports seriously and will review the content accordingly. If necessary, appropriate action will be taken in accordance with our community guidelines. We appreciate your help in keeping the community safe and respectful!",
  UNFOLLOWED: "User unfollowed successfully!"
}


// Customizable Area End
