import React from "react";
// Customizable Area Start
import { Typography, styled, Popover } from "@material-ui/core";

import ContentModerationController, {
  configJSON,
} from "./ContentModerationController";
import { optionIcon } from "../../../blocks/landingpage/src/assets";
import { archiveIcon, followIcon, reportIcon, unfollowIcon, viewProfile, editIcon, deleteIcon, viewIcon } from "./assets";
import ReportPostModal from "../../../components/src/ReportPostModal";
import UnfollowConfirmationModal from "../../../components/src/UnfollowConfirmationModal";
import ArchivePostModal from "../../../components/src/ArchivePostModal";
import CustomToaster from "../../../components/src/CustomToaster";



const StyledPopover = styled(Popover)({
  "& .MuiPaper-rounded": {
    padding: "10px",
    borderRadius: "8px",
    width: "122px"
  },
});

const ModerationContent = styled(Typography)({
  display: "flex",
  alignItems: "center",
  color: "#334155",
  fontWeight: 600,
  fontSize: "14px",
  cursor: "pointer",
  height: "34px",
  width: "114px"
})
// Customizable Area End  

class ContentModeration extends ContentModerationController {
  // Customizable Area Start

  renderFeedItem () {
    return <>
      {this.props.title !== 'ads' && <Typography style={webStyle.contentItem} data-test-id="view-profile" onClick={() => this.navigateToPage("UserProfileBasicBlock", this.props.userId)}>
        <img src={viewProfile} style={webStyle.popoverIcon} /> {configJSON.moderationList.VIEW_PROFILE}
      </Typography>}
      {this.showReportPostOption() && <Typography
        style={webStyle.contentItem}
        onClick={() => this.toggleReportModal(true)}
        data-test-id="report-post"
      >
        <img src={reportIcon} style={webStyle.popoverIcon} />
        {configJSON.moderationList.REPORT_POST}
      </Typography>}
      {this.showFollowUserOption() && this.props.title !== 'ads' && (this.state.isUnfollowed
       ? (
        <Typography
          style={{ ...webStyle.contentItem, color: "#DC2626" }}
          onClick={() => this.toggleUnfollowModal(true)}
          data-test-id="unfollow-user"
        >
          <img src={unfollowIcon} style={webStyle.popoverIcon} />
          {configJSON.moderationList.UNFOLLOW_USER}
        </Typography>
      ) : (
        <Typography
          style={(this.state.isRequestSuccess || this.props?.postByUser?.is_follwed === "pending") ? webStyle.requestedFont : webStyle.contentItem1}
          data-test-id="follow-user"
          onClick={this.followUser}
        >
          <img src={followIcon} style={webStyle.popoverIcon} />
          {(this.state.isRequestSuccess || this.props?.postByUser?.is_follwed === "pending") ? configJSON.moderationList.REQUESTED : configJSON.moderationList.FOLLOW_USER}
        </Typography>
      ))}
      {this.showArchivePostOption() && <Typography
     style={{...webStyle.contentItem, marginTop:"8px", marginBottom:"2px"}}
        onClick={this.openArchiveModal}
        data-test-id="archive-post"
      >
     <img src={archiveIcon} style={{...webStyle.popoverIcon, height:"12px", width:"12px"}} />
        {configJSON.moderationList.ARCHIEVE}
      </Typography>}
    </>
  }

  renderMenuItem () {
    return (
      <StyledPopover
        data-test-id="popover-wrapper"
        id={Boolean(this.state.anchorEl) ? "simple-popover" : undefined}
        open={Boolean(this.state.anchorEl)}
        anchorEl={this.state.anchorEl}
        onClose={this.handlePopverClose}
        anchorPosition={{ left: 75, top: 100 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock={false}
        disableRestoreFocus={true}
      >
        {this.props.title === "profile" || this.props.title === "repost" ?
          <>
            {
              this.props.title === "repost" ?
          <ModerationContent style={{width: '129px'}} data-test-id="view-orignal" onClick={() => this.navigateToPage("ViewPost", this.props.orignalPostId)}>
                  <img src={viewIcon} style={webStyle.popoverIcon} /> {configJSON.moderationList.ORIGINAL}
                </ModerationContent>
                :
                <ModerationContent data-test-id="edit" onClick={() => this.navigateToPage("EditPost", this.props.postId)}>
                  <img src={editIcon} style={webStyle.popoverIcon} /> {configJSON.moderationList.EDIT}
                </ModerationContent>
            }
            <ModerationContent
              onClick={this.handleDeleteModal}
              data-test-id="delete"
            >
              <img src={deleteIcon} style={webStyle.popoverIcon} />
              {configJSON.moderationList.DELETE}
            </ModerationContent>
            {this.props.title !== "repost" && this.showArchivePostOption() && <ModerationContent
              onClick={this.openArchiveModal}
              data-test-id="archive-post"
            >
              <img src={archiveIcon} style={webStyle.popoverIcon} />
              {configJSON.moderationList.ARCHIEVE}
            </ModerationContent>}
          </>
           : this.renderFeedItem() }
      </StyledPopover>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <>
        <img
          src={optionIcon}
          alt=""
          data-test-id="pop-over-option"
          style={{ cursor: "pointer" }}
          aria-describedby={
            Boolean(this.state.anchorEl) ? "simple-popover" : undefined
          }
          onClick={this.handlePopoverClick}
        />
        {this.renderMenuItem()}
        {this.state.isReportModalOpen && <ReportPostModal
          isOpen={this.state.isReportModalOpen}
          handleClose={this.toggleReportModal}
          reportPost={this.reportPost}
          reportData={this.state.reportData}
          postId={this.props.postId}
        />}
        <ArchivePostModal
          isOpen={this.state.isArchiveModalOpen}
          handleClose={this.closeArchiveModal}
          onPurchase={this.showCodeLink}
          onArchive={this.postarchiveCall}
          getCode={this.changeCode}
          isDisable={Boolean(!this.state.archiveCode)}
        />
        <UnfollowConfirmationModal
          data-test-id="unfollow-modal"
          isOpen={this.state.isUnfollowModalOpen}
          handleClose={this.toggleUnfollowModal}
          unfollowUser={this.unFollowUser}
          userName={String(this.props.userName)}
        />
        <UnfollowConfirmationModal
          data-test-id="delete-modal"
          title="delete"
          isOpen={this.state.isDeleteModalOpen}
          handleClose={this.handleDeleteModal}
          unfollowUser={this.deletePost}
        />
        <CustomToaster />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start

const webStyle : { [key: string]: React.CSSProperties } = {
  requestedFont: {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    cursor: "not-allowed",
    fontSize: "14px",
    marginBottom: "10px",
    color: "#334155",
    opacity: "0.7"
  },
  contentItem: {
    display: "flex",
    alignItems: "center",
    color: "#334155",
    fontWeight: 600,
    fontSize: "14px",
    marginBottom: "10px",
    cursor: "pointer"
  },
  contentItem1: {
    display: "flex",
    alignItems: "center",
    color: "#334155",
    fontWeight: 600,
    fontSize: "14px",
    cursor: "pointer"
  },
  popoverIcon: {
    height: "16px",
    width: "16px",
    marginRight: "5px"
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "100px",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

export default ContentModeration;

// Customizable Area End
