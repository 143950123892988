import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Modal, Button, Typography, TextField, Grid } from "@material-ui/core";

interface ArchivePostModalProps {
  isOpen: boolean;
  handleClose: () => void;
  onPurchase: () => void;
  onArchive: () => void;
  getCode: (code: string) => void;
  isDisable: boolean
}

const ArchivePostModal: React.FC<ArchivePostModalProps> = ({
  isOpen,
  handleClose,
  onPurchase,
  onArchive,
  getCode,
  isDisable,
}) => {
  
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div
        style={{
          padding: "20px",
          background: "#fff",
          borderRadius: "16px",
          margin: "auto",
          maxWidth: "400px",
          textAlign: "center",
          position: "relative",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <CloseIcon
          onClick={handleClose}
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
            cursor: "pointer",
            color: "#666",
          }}
        />

        <Typography
          variant="h6"
          style={{
            marginBottom: "20px",
            fontWeight: 700,
            color: "#333",
          }}
        >
          Archive Post
        </Typography>

        <Typography
          style={{
            marginBottom: "20px",
            fontSize: "14px",
            color: "#333",
            lineHeight: "1.5",
            fontWeight: "bold",
            
          }}
        >
          Are you sure you want to archive this post and ensure you have a proper Shopify purchase setup?
        </Typography>

        <Grid container spacing={2} alignItems="center" style={{ marginBottom: "20px" }}>
          <Grid item xs={7}>
            <TextField
              label="Enter Archive Code"
              variant="outlined"
              fullWidth
              InputProps={{
                style: {
                  fontSize: "14px",
                  borderRadius: "8px",
                  fontFamily: "Montserrat",
                  height: "48px",
                  justifyContent:"center",
                  alignItems:"center"
                },
              }}
              onChange={(event) => getCode(event.target.value)}
            />
          </Grid>
          <Grid item xs={5}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#A35858",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "8px",
                textTransform: "none",
                fontWeight: 600,
                width: "100%",
                height: "48px",
                fontFamily: "Montserrat",

              }}
              onClick={onPurchase}
            >
              Purchase slot
            </Button>
          </Grid>
        </Grid>

        <Button
          variant="contained"
          onClick={onArchive}
          disabled={isDisable}
          style={{
            backgroundColor: isDisable ? 'grey' : "#A35858",
            color: "#fff",
            padding: "10px 20px",
            borderRadius: "8px",
            textTransform: "none",
            fontWeight: 600,
            width: "100%",
            height: "48px",
            fontFamily: "Montserrat",

          }}
        >
          Process
        </Button>
      </div>
    </Modal>
  );
};

export default ArchivePostModal;
