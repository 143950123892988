import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { callApi } from "../../../components/src/Utilities";
import { toast } from "react-hot-toast";
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  postId?: number;
  userId?: number;
  isFollowing?: boolean;
  userName?: string;
  postByUser?: {id: number; email: string, is_follwed?: string | null}
  title?: string;
  getData: any;
  orignalPostId?: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: any;
  postId: string;
  isReportModalOpen: boolean;
  isUnfollowModalOpen: boolean;
  isRequestSuccess: boolean;
  reportReasons: [];
  reportData:any[];
  isArchiveModalOpen: boolean;
  isUnfollowed: boolean;
  isDeleteModalOpen: boolean;
  archiveCode: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContentModerationController extends BlockComponent<
  Props,
  S,
  SS
> {

  loginDataApiCallId: string | null = "";
  // Customizable Area Start
  unfollowUserId: string = "";
  reportPostId: string = "";
  sendFollowRequestId: string = "";
  getReportReasonsId: string = "";
  reportDataID: string = "";
  deleteApiCallID: string = "";
  processCodeLinkApiCall: string = "";
  postArchiveApiCall: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      anchorEl: null,
      isReportModalOpen: false,
      isUnfollowModalOpen: false,
      isRequestSuccess: false,
      reportReasons: [],
      reportData:[],
      postId: "",
      isArchiveModalOpen: false,
      isUnfollowed: false,
      isDeleteModalOpen: false,
      archiveCode: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (errorReponse || responseJson?.errors || responseJson?.error) {
      this.apiFailureCallBacks();
    }

    if(apiRequestCallId === this.reportDataID){
      this.setState({reportData : responseJson.data })
    }

    else if (responseJson && !responseJson?.errors) {
      this.apiSuccessCallBacks(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    if(this.props.postByUser?.is_follwed === 'accepted') this.setState({isUnfollowed: true})
    this.getReportReasons();
    this.reportData();
    // Customizable Area End
  }

// Customizable Area Start
  handleFakeLoginUser = () => {
    const body = {
      data: {
        attributes: {
          email: configJSON.loginEmail,
          password: configJSON.loginPassword,
        },
        type: "email_account",
      },
    };

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.reportPostId){
      toast.success(configJSON.feedbackMsg.POST_REPORT);
      this.handlePopverClose();
    }

    if (apiRequestCallId === this.unfollowUserId){
      this.setState({isUnfollowed: false})
      toast.success(configJSON.feedbackMsg.UNFOLLOWED);
    }

    if (apiRequestCallId === this.sendFollowRequestId){
      this.setState({
        isRequestSuccess: true
      })
    }

    if (apiRequestCallId === this.getReportReasonsId){
      this.setState({
        reportReasons: responseJson
      })
    }

    if(apiRequestCallId === this.deleteApiCallID){
      this.handlePopverClose();
      this.setState({isDeleteModalOpen: false});
      this.props.getData();
    }

    if(apiRequestCallId === this.postArchiveApiCall) {
      this.closeArchiveModal();
      this.setState({anchorEl: null})
      if(responseJson?.success === false) toast.error(responseJson.message)
    }
  }

  navigateWebToTagList = () => {
    this.props.navigation.navigate("CreateContentWeb");
  };
  navigateToTagList = () => {
    this.props.navigation.navigate("CreateContent");
  };

  navigateToAnalyse = () => {
    this.props.navigation.navigate("ContentList");
  };
  navigateWebToAnalyse = () => {
    this.props.navigation.navigate("ContentListWeb");
  }

  apiFailureCallBacks = () => {
    toast.error(configJSON.feedbackMsg.FETCH_ERR);
  }
  handlePopverClose = () => {
    this.setState({
      anchorEl: null
    })
  };

  toggleReportModal = (toggle: boolean) => {
    this.setState({
      isReportModalOpen: toggle
    })
  }

  toggleArchiveModal = (toggle: boolean): void => {
    this.setState({ isArchiveModalOpen: toggle });
  };

  openArchiveModal = (): void => {
    this.setState({ isArchiveModalOpen: true });
  };
  closeArchiveModal = (): void => {
    this.setState({ isArchiveModalOpen: false, archiveCode: '' });
  };

  toggleUnfollowModal = (toggle: boolean) => {
    this.setState({
      isUnfollowModalOpen: toggle
    })
  }

  handlePopoverClick = (event: any) => {
    this.setState({
      anchorEl: event?.currentTarget
    })
  }


  reportData = async () => {

    const authToken = await getStorageData("token");

    this.reportDataID = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.exampleWebAPiEndPoint,
      headers: { "token": authToken },
    }, runEngine);

  }

  reportPost = async ( reasonId: number, postId: number | undefined ) => {

    const authToken = await getStorageData("token");
    const text_content = (this.state.reportData?.find((el: any) => el.id == reasonId) as any)?.attributes.text_content;
    
    this.reportPostId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.methodTypes.HTTP_POST,
      endPoint: `${(configJSON.endpoints.REPORT_POST as string)}`,
      headers: { "token": authToken },
      body: {
        post_id:postId,
        reported_text: text_content || ""
      }
    }, runEngine);

  }


  unFollowUser = async () => {

    const authToken = await getStorageData("token");
    const { userId } = this.props;

    this.unfollowUserId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.methodTypes.HTTP_DELETE,
      endPoint: `${(configJSON.endpoints.UNFOLLOW_USER as string)}/${userId}`,
      headers: { "token": authToken },
    }, runEngine);

  }


  followUser = async () => {
    const authToken = await getStorageData("token");

    const payload = {
      following_id: this.props.userId,
    };

    this.sendFollowRequestId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.methodTypes.HTTP_POST,
      endPoint: `${(configJSON.endpoints.SEND_REQUEST as string)}`,
      body: payload,
      headers: { "token": authToken},
    }, runEngine);
  }
  

  navigateToPage = (pageName: string, param?: string | number) => {
    const navigatnMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigatnMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigatnMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    navigatnMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), String(param));
    this.send(navigatnMessage);
  };


  getReportReasons = async () => {
    const authToken = await getStorageData("token");

    this.getReportReasonsId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.methodTypes.HTTP_GET,
      endPoint: `${(configJSON.endpoints.GET_REPORT_REASONS as string)}`,
      headers: { "token": authToken},
    }, runEngine);
  }

  showFollowUserOption = () => {
    let user = localStorage.getItem("currUser");    
    if(this.props.postByUser?.email === user) {
      return false
    }
    return true;
  }

  showReportPostOption = () => {
    let user = localStorage.getItem("currUser");    
    if(this.props.postByUser?.email === user) {
      return false
    }
    return true;
  }
  handleDeleteModal = () => {
    this.setState({isDeleteModalOpen: !this.state.isDeleteModalOpen})
  }

  deletePost = async() => {
    const authToken = await getStorageData("token");

    this.deleteApiCallID = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.methodTypes.HTTP_DELETE,
      endPoint: `/bx_block_posts/posts/${this.props.postId}`,
      headers: { "token": authToken},
    }, runEngine);
  }

  showArchivePostOption = () => {
    let user = localStorage.getItem("currUser");    
    if(this.props.postByUser?.email!== user) {
      return false
    }
    return true;
  }

  showCodeLink = async() => {
    const authToken = await getStorageData("token");

    this.processCodeLinkApiCall = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.codeLinkEndPoint}${this.props.userId}`,
      headers: { "token": authToken},
    }, runEngine);
  };

  postarchiveCall = async () => {
    const authToken = await getStorageData("token");
      this.postArchiveApiCall = callApi({
        contentType: configJSON.apiContentType,
        method: "POST",
        endPoint: `${configJSON.post_archiveEndPoint}${this.state.archiveCode}&id=${this.props.postId}`,
        headers: { "token": authToken},
      }, runEngine);
  };

  changeCode = (code: string) => {
    this.setState({archiveCode: code})
  }
  // Customizable Area End
}

