import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  IconButton,
  Typography,
  styled,
  Avatar
} from "@material-ui/core";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomSkeleton from "../../../components/src/CustomSkeleton";
import { crossIcon } from "../../../blocks/pushnotifications/src/assets";
import { goToPrevPage } from "../../../components/src/Utilities";
import PostCard from "../../../components/src/PostCard";
import Likeapost2 from "../../../blocks/likeapost2/src/Likeapost2.web";
import {
  shareIcon,
  commentIcon
} from "../../../blocks/landingpage/src/assets";
import Comments from "../../../blocks/comments/src/Comments.web";
// Customizable Area End

import PhotoLibraryController, {
  Props,
  configJSON
} from "./PhotoLibraryController";

// Customizable Area Start

const StyledBox = styled(Box)({
  "&.media-container": {
    display: "flex",
    flexWrap: "wrap",
    "@media (max-width: 1225px)": {
      justifyContent: "center",
    },
  },
});

// Customizable Area End

export default class PhotoLibrary extends PhotoLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getPostsGrid = () => {
    return (
      <StyledBox className="media-container">
        {this.state.libraryData.map(
          (item, index) => {
            return this.state.isLoading ? (
              <Box
                key={index}
                className="media-container"
                style={{ position: "relative", width: "fit-content" }}
              >
                <CustomSkeleton
                  variant="rect"
                  style={webStyles.mediaStyle}
                />
              </Box>
            ) : (
              <Box
                key={index}
                className="media-container"
                style={{ position: "relative", width: "fit-content" }}
              >
                {item.type === "image" ? <img src={item.image_uri}
                  data-test-id="media-tile"
                  style={this.state.isFullScreen ? webStyles.fullScreenImg : webStyles.mediaStyle}
                  alt="" /> : (
                  <video data-test-id="video-tile" autoPlay style={this.state.isFullScreen ? webStyles.fullScreenImg : webStyles.mediaStyle} onClick={() => this.handleImageFullScreen(true, item.image_uri)}>
                    <source src={item.image_uri} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </Box>
            );
          }
        )}
        {this.state.isFullScreen && <Box>
          {(new URL(this.state.fullScreenImageUrl).searchParams.get("type") === "image" || this.validateImage(this.state.fullScreenImageUrl)) ? <img src={this.state.fullScreenImageUrl} data-test-id="media-tile2" style={webStyles.fullScreenImg} /> : (
            <video autoPlay style={webStyles.fullScreenImg}>
              <source src={this.state.fullScreenImageUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          <img
            src={crossIcon}
            style={webStyles.crossIcon}
            onClick={() => this.handleImageFullScreen(false, "")}
            data-test-id="cross-btn"
            alt=""
          />
        </Box>}
      </StyledBox>
    )
  }

  getPostDetail = () => {
        return (
      <div style={{ display: "flex", justifyContent: "center", width: '100%' }}>
        <div style={{ width: "542px", height: "573px", display: "flex", flexDirection: "column", gap: "12px" }}>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} minHeight={"44px"} marginTop={'25px'}>
            <Box display={"flex"} width={'194px'}>
              <div style={{ display: "flex" }}>
                <Avatar
                  alt="profile"
                  src={this.state?.selectedPost?.attributes?.post_by_user.profile_image}
                  style={{
                    height: "44px",
                    width: "44px",
                    border: "2px solid #F3E1DC",
                  }}
                />
                <Typography
                  style={{
                    marginLeft: "16px",
                    color: "#0F172A",
                    fontSize: "16px",
                    fontWeight: 600,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    lineHeight: "24px",
                  }}
                  data-test-id="user-name"
                  onClick={() => this.navigateToPost("UserProfileBasicBlock", this.state?.selectedPost?.attributes?.post_by_user?.id)}
                >
                  {this.state?.selectedPost?.attributes?.post_by_user?.email?.split("@")[0]}
                </Typography>
              </div>
            </Box>
          </Box>
          <img style={{borderRadius: "8px", width: "542px", height: "305px"}} src={this.state?.selectedPost?.attributes?.images_and_videos[0]?.url} />
          <Box display={"flex"} style={{ gap: "12px" }}>
            <Likeapost2
              navigation={undefined}
              id={""}
              postId={this.state?.selectedPost?.attributes?.images_and_videos[0]?.post_id}
              isLiked={this.state?.selectedPost?.attributes?.liked}
            />
            <img
              src={commentIcon}
              alt=""
              data-test-id="user-comment"
              onClick={() => this.navigateToPost("Repost", this.state?.selectedPost?.attributes?.images_and_videos[0]?.post_id)}
              style={{ cursor: "pointer" }}
            />
            <img
              src={shareIcon}
              alt=""
              data-test-id="share-icon"
              onClick={() => this.navigateToPost("Repost", this.state?.selectedPost?.attributes?.images_and_videos[0]?.post_id)}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Box style={{ position: "relative", display: "flex", flexDirection: "column" }} height={"100%"}>
            {this.state?.selectedPost?.attributes?.like_count > 0 && <Typography
              style={{ color: "#0F172A", fontSize: "16px" }}
            >
              Loved by
              <span style={{ fontWeight: 600, cursor: "pointer" }} data-test-id="liked-user" onClick={this.handleLike}> {this.state?.selectedPost?.attributes?.like_by_user?.email?.split("@")[0]}</span>
              {this.state?.selectedPost?.attributes?.like_count > 1 && <span data-test-id="expand-text" onClick={this.handleMore} style={{ fontWeight: 600, cursor: "pointer" }}> <span style={{fontWeight: 400}}>and</span> {this.state?.selectedPost?.attributes?.like_count - 1} others</span>}
            </Typography>}
            <Box overflow={"auto"} marginBottom={'10px'}>
              <Typography
                style={{ color: "#334155", fontSize: "16px" }}
              >
               <span style={{fontWeight: 700}}>{this.state?.selectedPost?.attributes?.post_by_user?.email?.split("@")[0]}</span> {this.state?.selectedPost?.attributes?.description}
              </Typography>
            </Box>
            <Comments navigation={undefined} id={""} postId={this.state?.selectedPost?.attributes?.images_and_videos[0]?.post_id} commentCount={this.state?.selectedPost?.attributes?.comment_count} />
          </Box>
        </div>
      </div>
    )
  }

  getAllPost = () => {
    const filteredData = this.state?.postData?.filter((post: any) => this.state.postId === 'repost' ? Boolean(post.attributes?.is_repost?.post) : !Boolean(post.attributes?.is_repost?.post))

    return(
      <>
      <div style={{width: "100%", display: 'flex', justifyContent: 'center', paddingLeft: "16px"}}>
      <Typography style={{...webStyles.subtitleStyle, width: '880px'}}>
        All {this.state.postId === 'repost' ? 'reposts' : 'Posts'}
      </Typography>
      </div>
      {
        filteredData && filteredData.map((post: any, index: number) => {
          return <div style={{display: "flex", justifyContent: "center"}}>
            <PostCard
              title={this.state.postId === 'repost' ? 'repost' : "profile"}
              description={post.attributes?.description || ""}
              index={index}
              liked={post.attributes?.liked}
              likeCount={post.attributes?.like_count}
              likeBy={post.attributes?.like_by}
              likeByUser={post.attributes?.like_by_user}
              postByUser={post.attributes?.post_by_user}
              userProfile={post.attributes?.post_by_user?.profile_image}
              postMedia={post.attributes?.images_and_videos}
              commentCount={post.attributes?.comment_count}
              postId={Number(post.id)}
              navigationFn={this.navigateToPost}
              isRepost={Boolean(post.attributes?.is_repost?.post)}
              repostData={post.attributes?.is_repost?.post}
              postData={post}
              navigation={this.props.navigation}
              getData={this.getLibraryData}
            />
          </div>
        })
      }
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <>
        <NavigationMenu navigation={this.props.navigation} id={"1"} />
        <Container style={webStyles.containerStyle} data-test-id="library-container">
          <Box display={"flex"} alignItems={this.state.postId === "gallery" && "center"}>
            <IconButton
              data-test-id="btn-back"
              style={webStyles.IconBtnStyle}
              onClick={() => goToPrevPage()}
            >
              <ArrowBackIcon htmlColor="#fff" />
            </IconButton>
            {this.state.postId === "gallery" && <Typography style={webStyles.titleStyle}>
              {configJSON.libraryTitle}
            </Typography>}
            {
              this.state.postId !== "gallery" && this.state.postId !== "post" && this.state.postId !== "repost" && this.getPostDetail()
            }
          </Box>
          {
            (this.state.postId === "gallery" || this.state.postId === "post" || this.state.postId === "repost")  && <>
              {this.state.currLibraryType === configJSON.libraryType.GALLERY && <Typography style={webStyles.subtitleStyle}>
                {configJSON.gallery}
              </Typography>}
              {this.state.currLibraryType === configJSON.libraryType.GALLERY ? this.getPostsGrid() : this.getAllPost()}
            </>
              
          }
        </Container>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles: { [key: string]: React.CSSProperties } = {
  containerStyle: { marginTop: "50px" },
  titleStyle: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#282627",
  },
  IconBtnStyle: {
    height: "50px",
    width: "50px",
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    borderRadius: "8px",
    padding: "10px",
    lineHeight: "32px",
    marginRight: "15px",
  },
  subtitleStyle: {
    fontSize: "20px",
    fontWeight: 700,
    margin: "40px 0px",
    lineHeight: '24px'
  },
  mediaStyle: {margin: "2px", cursor: "pointer", width: "229px", height: "229px"},
  crossIcon: {
    zIndex: 9999999,
    position: "absolute",
    top: "25px",
    right: "45px",
    height: "40px",
    width: "40px"
  },
  fullScreenImg: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000,
  },
  playIconStyles: {
    position: "absolute",
    top: "40%",
    right: "40%",
    cursor: "pointer",
    zIndex: 100000
  }
};
// Customizable Area End
