import React from "react";

import { Box, Button, Container, Grid, Typography } from "@material-ui/core";

// Customizable Area Start
import AdManagerController, { Props } from "./AdManagerController.web";
import PostCard from "../../../components/src/PostCard";

// Customizable Area End

export default class AdManager extends AdManagerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <PostCard
        description={this.props.adsData.attributes?.description || ""}
        liked={this.props.adsData.attributes?.liked}
        likeCount={this.props.adsData.attributes?.like_count}
        likeBy={this.props.adsData.attributes?.like_by}
        likeByUser={this.props.adsData.attributes?.like_by_user}
        postByUser={this.props.adsData.attributes?.post_by_user}
        userProfile={this.props.adsData.attributes?.post_by_user?.profile_image}
        postMedia={this.props.adsData.attributes?.image}
        commentCount={this.props.adsData.attributes?.comment_count}
        postId={Number(this.props.adsData.id)}
        navigationFn={this.props.navigation}
        isRepost={Boolean(this.props.adsData.attributes?.is_repost?.post)}
        repostData={this.props.adsData.attributes?.is_repost?.post}
        postData={this.props.adsData}
        navigation={this.props.navigation}
        title="ads"
      />
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
